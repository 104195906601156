import {LiveAnnouncer} from '@angular/cdk/a11y';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {CommonModule} from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators} from '@angular/forms';
import {MatAutocompleteModule, MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatChipInputEvent, MatChipsModule} from '@angular/material/chips';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {
  AlgoliaPublishedQuiz,
  CollaborativeProject,
  createQuizParams,
  CreateQuizParams,
  createSubQuizParams,
  DEFAULT_PLATFORM_PROJECT_TYPE_DICT,
  EC_ENVIRONMENT,
  EvolutionCognitionEnvironment,
  GRADE_LEVELS,
  GradeLevel,
  Quiz
} from '@gigasoftware/evolving-cognition/domain';
import {AlgoliaChipsAutocomplete} from '@gigasoftware/shared/domain';
import {
  DlcFormFieldModule,
  DlcInputDirective,
  DlcRoundedTextButtonComponent,
  DlcSelectModule
} from '@gigasoftware/shared/ui-design-library';
import {uuid} from '@ngpat/fn';
import {NgPatUserAccount, selectNgPatUserAccount} from '@ngpat/store';
import {select, Store} from '@ngrx/store';
import {BehaviorSubject, from, Observable, Subject, switchMap} from 'rxjs';
import {map, startWith, take} from 'rxjs/operators';

@Component({
  selector: 'ec-create-quiz',
  templateUrl: './ec-create-quiz.component.html',
  styleUrls: ['./ec-create-quiz.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'ec-form-create-quiz'
  },
  standalone: true,
  imports: [
    CommonModule,
    DlcFormFieldModule,
    DlcInputDirective,
    DlcRoundedTextButtonComponent,
    DlcSelectModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatCheckboxModule,
    MatChipsModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule
  ]
})
export class EcCreateQuizComponent implements OnInit, OnDestroy {
  private _onDestroy$: Subject<boolean> = new Subject();
  private _quiz: Quiz | null = null;

  showProjectTypeSelect$: BehaviorSubject<boolean>;
  showCollaborationOption = false;
  nameFormGroup: FormGroup;

  @Input() showIsPrivate = false;

  @Input() showAssignedOption = true;

  @Input() showAggregateFalseAnswersOption = false;

  @Input()
  set quiz(q: Quiz | null) {
    this._quiz = q;
  }

  @Input() showCollaborativeOption = false;

  @Input() parentProject: CollaborativeProject | null | undefined = null;

  @Output() createAction: EventEmitter<CreateQuizParams> = new EventEmitter<CreateQuizParams>();

  get hasParentProject() {
    return this.parentProject !== null && this.parentProject !== undefined;
  }

  get showPrivateOption() {
    return this.nameFormGroup.value.isCollaborative;
  }

  // GRADE LEVELS
  gradeLevels: GradeLevel[] = GRADE_LEVELS;
  filteredGradeLevels!: Observable<GradeLevel[]>;

  get gradeLevelControl() {
    return this.nameFormGroup.controls['gradeLevel'];
  }

  // AUTOCOMPLETE FOR SUBJECTS
  algoliaSubjectAutocomplete: AlgoliaChipsAutocomplete<AlgoliaPublishedQuiz>;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  filteredSubjects!: Observable<AlgoliaPublishedQuiz[]>;

  get subjectControl() {
    return this.nameFormGroup.controls['subjects'];
  }

  @ViewChild('subjectInput') subjectInput!: ElementRef<HTMLInputElement>;

  constructor(
    private store: Store,
    private fb: FormBuilder,
    private cd: ChangeDetectorRef,
    private announcer: LiveAnnouncer,
    @Inject(EC_ENVIRONMENT) private environment: EvolutionCognitionEnvironment
  ) {
    this.nameFormGroup = this.fb.group({
      name: new FormControl(null, [Validators.required]),
      description: new FormControl(null),
      isPrivate: new FormControl(true),
      gradeLevel: new FormControl(null),
      subjects: new FormControl(null),
      isCollaborative: new FormControl(false),
      assigned: new FormControl(false),
      aggregateFalseAnswers: new FormControl(true)
    });

    this.showProjectTypeSelect$ = new BehaviorSubject<boolean>(true);

    this.algoliaSubjectAutocomplete = new AlgoliaChipsAutocomplete<AlgoliaPublishedQuiz>({
      appId: this.environment.algoliaSubjects.appId,
      apiKey: this.environment.algoliaSubjects.apiKey,
      index: this.environment.algoliaSubjects.index
    });
  }

  ngOnInit(): void {
    this.showCollaborationOption = !this.hasParentProject && this.showCollaborativeOption;
    this.nameFormGroup.controls['isCollaborative'].setValue(
      this.parentProject !== null && this.parentProject !== undefined
    );

    // GRADE LEVELS
    this.filteredGradeLevels = this.gradeLevelControl.valueChanges.pipe(
      startWith(''),
      map(value => {
        const name = typeof value === 'string' ? value : value?.name;
        return name ? this._filter(name as string) : this.gradeLevels.slice();
      })
    );

    // END GRADE LEVELS

    // AUTOCOMPLETE FOR SUBJECTS

    this.filteredSubjects = this.subjectControl.valueChanges.pipe(
      startWith(null),
      switchMap((subject: string) => {
        if (typeof subject === 'string' && subject && subject.length > 2) {
          return this.algoliaSubjectAutocomplete.search<AlgoliaPublishedQuiz>(subject);
        }
        return from([]);
      })
    );

    // END AUTOCOMPLETE FOR SUBJECTS

    this.cd.detectChanges();
  }

  ngOnDestroy() {
    this._onDestroy$.next(true);
  }

  onSubmit() {
    if (this.nameFormGroup.valid) {
      const params: CreateQuizParams = {
        id: uuid(),
        projectTypeValue: DEFAULT_PLATFORM_PROJECT_TYPE_DICT.QUIZ.id,
        // parentProject: this.parentProject,
        isCollaborative: this.nameFormGroup.value.isCollaborative,
        isPrivate: this.nameFormGroup.value.isPrivate,
        subjects: this.algoliaSubjectAutocomplete.getSelectedAsString(),
        description: this.nameFormGroup.value.description,
        name: this.nameFormGroup.value.name,
        gradeLevel: (<GradeLevel | null>this.nameFormGroup.value.gradeLevel)?.level || null,
        aggregateFalseAnswers: !!this.nameFormGroup.value.aggregateFalseAnswers
      };

      this.store.pipe(select(selectNgPatUserAccount), take(1)).subscribe((userAccount: NgPatUserAccount) => {
        if (userAccount.uid) {
          // if (params.parentProject) {
          if (this.parentProject) {
            this.store.dispatch(
              createSubQuizParams({
                params: {
                  ...params,
                  parentProject: this.parentProject
                }
              })
            );
          } else {
            this.store.dispatch(createQuizParams({params}));
          }

          this.createAction.emit(params);
        }
      });
    }
  }

  // GRADE LEVELS
  displayFn(user: GradeLevel): string {
    return user && user.name ? user.name : '';
  }

  private _filter(name: string): GradeLevel[] {
    const filterValue = name.toLowerCase();

    return this.gradeLevels.filter(option => option.name.toLowerCase().includes(filterValue));
  }

  // AUTOCOMPLETE FOR SUBJECTS
  /**
   * @param event
   */
  algoliaAutoCompleteAdd(event: MatChipInputEvent): void {
    this.algoliaSubjectAutocomplete.add(event);
    this.subjectControl.setValue(null);
  }

  /**
   * @param subject
   */
  algoliaAutoCompleteRemove(subject: string): void {
    const index = this.algoliaSubjectAutocomplete.remove(subject);

    if (index >= 0) {
      this.announcer.announce(`Removed ${subject}`);
    }
  }

  algoliaAutoCompleteSelect(event: MatAutocompleteSelectedEvent): void {
    this.algoliaSubjectAutocomplete.selected(event);
    this.subjectInput.nativeElement.value = '';
    this.subjectControl.setValue(null);
  }

  // END AUTOCOMPLETE FOR SUBJECTS
}
